<template>
  <div>
    <Header :languagesProp="'English'" />
    <div class="contentBox">
      <!-- banner -->
      <div class="bannerBox">
        <a href="https://www.paisayaar.pk/home" target="_blank">
          <img src="@/assets/image/newHome/jumpPaisayaar.png" alt="" />
        </a>
      </div>
      <!-- Company Vision -->
      <div class="companyBox">
        <p class="remarksText">
          محفوظ، قابل بھروسہ اور انسان دوستی سمیت مالیاتی
        </p>
        <h2>کمپنی وژن</h2>
        <ul>
          <li>
            <img src="@/assets/image/newHome/vision1.png" alt="" />
            <p>
              حفاظت، سالمیت اور پیشہ ورانہ خدمات اینکرز کے طور پر
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/vision2.png" alt="" />
            <p>
              پیشہ ور، موثر، سائنسی اور تکنیکی جدت
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/vision3.png" alt="" />
            <p>
              ٹیکنالوجی پر مبنی مالیاتی فراہم کرنے کے لئے سروس بدعت درست مالی
              کریڈٹ پاکستانی صارفین کے لیے خدمات۔
            </p>
          </li>
        </ul>
      </div>
      <!-- about Us -->
      <div class="companyBox aboutBox">
        <p class="remarksText">
          دوسروں پر فائدہ
        </p>
        <h2>پلیٹ فارم کے فوائد</h2>
        <ul>
          <li>
            <img src="@/assets/image/newHome/about1.png" alt="" />
            <h5>سروس کے فوائد</h5>
            <p>
              پیشہ ورانہ، سائنسی اور ہمہ جہت ڈیجیٹل آپریشن
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/about2.png" alt="" />
            <h5>ٹیم کے فوائد</h5>
            <p>
              یہ ایک بالغ اور پیشہ ور ہے مالیاتی ٹیم، آپریشن اور تکنیکی ٹیمیں
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/about3.png" alt="" />
            <h5>محفوظ</h5>
            <p>
              خفیہ کاری کے لیے انکرپشن الگورتھم استعمال کریں۔ آپ کی تمام معلومات
              پورے عمل، کے لئے ایک دیوار کی تعمیر رازداری
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/about4.png" alt="" />
            <h5>ڈیجیٹل خطرہ کنٹرول سسٹم</h5>
            <p>
              مضبوط سائنسی اور تکنیکی اختراعی صلاحیت، ڈیجیٹل خطرہ کنٹرول ماڈل
            </p>
          </li>
        </ul>
      </div>

      <!-- operation Team -->
      <div class="companyBox teamBox">
        <p class="remarksText">
          پیشہ ورانہ خدمت
        </p>
        <h2>آپریشن ٹیم</h2>
        <ul>
          <li>
            <img src="@/assets/image/newHome/team1.png" alt="" />
            <h5>ایک مضبوط اور پیشہ ور تکنیکی ٹیم</h5>
            <p>
              ہم مسلسل جدید ٹیکنالوجی سیکھتے ہیں اور پلیٹ فارم کے آپریشن کے
              استحکام پر ہمیشہ توجہ دیتے ہیں۔
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/team2.png" alt="" />
            <h5>پیشہ ورانہ اور بالغ مالیاتی ٹیم</h5>
            <p>
              ہمارے پاس مختلف خطوں سے بہترین ڈیٹا ایکچوری اور فنانشل ایکچوریز
              ہیں۔
            </p>
          </li>
          <li>
            <img src="@/assets/image/newHome/team3.png" alt="" />
            <h5>بہترین اور گہری آپریشن ٹیم</h5>
            <p>
              مقامی پالیسیوں اور ضوابط کو مسلسل سمجھتا اور سیکھتا ہے، اور پہلی
              بار پالیسیوں اور ضوابط کے مطابق کاروباری ایڈجسٹمنٹ کرے گا۔
            </p>
          </li>
        </ul>
      </div>

      <img
        src="@/assets/image/newHome/middleBannerUrdo.png"
        style="width:100%;margin-top:80px"
        alt=""
      />

      <!-- Contact Us -->
      <div class="companyBox">
        <p class="remarksText">
          سرکاری فون نمبر
        </p>
        <h2>ہم سے رابطہ کریں۔</h2>
        <div class="contactUs">
          <img src="@/assets/image/newHome/concatImg.png" alt="" />
          <ul>
            <li>051-111-883-883</li>
            <li>051-884-127-0</li>
            <li>0315-3003-873</li>
            <li>0308-8889-823</li>
            <li>0516011632 - 0516011932</li>
            <li>0518841270 - 0518841289</li>
            <li>0516000480 - 0516000654</li>
          </ul>
        </div>
      </div>

      <img
        src="@/assets/image/newHome/footImgUrdo.png"
        style="width:100%;"
        alt=""
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/headerNew.vue";
import Footer from "../../components/foot.vue";
export default {
  name: "",
  components: {
    Header,
    Footer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.contentBox {
  .bannerBox {
    position: relative;
    width: 100%;
    height: 610px;
    background: url("../../assets/image/newHome/bannerUrdo.png") no-repeat;
    background-size: 100% 100%;
    a {
      position: absolute;
      right: 13.3%;
      bottom: 13%;
      width: 310px;
      height: 76px;
    }
  }
  .companyBox {
    padding: 80px 260px 0;
    margin-bottom: 10px;
    h2 {
      font-size: 44px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #2a2a2a;
      margin: 10px 0 40px;
      text-align: right;
    }
    .remarksText {
      font-family: OpenSans, OpenSans;
      font-weight: 400;
      font-size: 24px;
      color: #2ea76a;
      text-align: right;
    }
    ul {
      display: flex;
      // margin-bottom: 85px;
      li {
        box-sizing: border-box;
        position: relative;
        margin-right: 40px;
        width: 440px;
        height: 579px;
        box-shadow: 3px 8px 15px 0px rgba(123, 123, 123, 0.16);
        border-radius: 24px;
        text-align: right;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #747474;
        line-height: 40px;
        &:last-child {
          margin-right: 0;
        }
        p {
          height: 252px;
          padding: 0 32px;
          font-family: OpenSans, OpenSans;
          font-weight: 400;
          font-size: 28px;
          color: #7c7c7c;
          display: flex;
          align-items: center;
        }

        img {
          width: 100%;
          height: 327px;
        }
      }
    }
  }

  .aboutBox {
    ul {
      li {
        width: 328px;
        height: 348px;
        box-shadow: 0 2px 15px 0 rgba(123, 123, 123, 0.16);
        h5 {
          font-family: OpenSans, OpenSans;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          text-align: right;
          margin: 24px 0;
          line-height: 30px;
          padding: 0 32px;
        }
        p {
          font-family: OpenSans, OpenSans;
          font-weight: 400;
          font-size: 19px;
          color: #666666;
          height: auto;
        }
        img {
          width: 72px;
          height: 72px;
          margin: 27px auto 0;
        }
      }
    }
  }

  .teamBox {
    ul {
      li {
        width: 472px;
        box-shadow: none;
        margin: 0;
        h5 {
          font-family: OpenSans, OpenSans;
          font-weight: 600;
          font-size: 32px;
          color: #2a2a2a;
          padding-right: 10px;
        }
        p {
          height: auto;
          padding: 22px 0 0 10px;
          font-size: 24px;
        }
      }
    }
  }

  .dividerBox {
    width: 1400px;
    height: 2px;
    background-color: #dedede;
    margin: 0 auto 107px;
    .contentBox {
      width: 128px;
      height: 2px;
      background: #2ea76a;
      margin: 0 auto;
    }
  }

  .contactUs {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    width: 100%;
    img {
      width: 490px;
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
      color: #2a2a2a;
      margin-bottom: 8px;
    }
    p {
      font-size: 24px;
      color: #7c7c7c;
      margin-bottom: 32px;
    }

    ul {
      display: block;
      li {
        width: 582px;
        height: auto;
        box-shadow: none;
        font-size: 28px;
        color: #333333;
        line-height: 52px;
        margin-right: 0;
      }
    }
  }

  .concatBox {
    padding: 0 143px;
    margin: 110px 0 151px;
    h2 {
      font-size: 44px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2a2a2a;
    }
    .concatContent {
      display: flex;
      margin-top: 137px;
      padding-left: 190px;
      justify-content: space-between;
    }
    .concatList {
      padding-top: 18px;
      li {
        display: flex;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 42px;
        margin-bottom: 46px;
        img {
          width: 50px;
          height: 50px;
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
